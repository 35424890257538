.casino-game {
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    .section-sub-title, h2 {
        @media (max-width: $lg) {
            text-align: left;
            padding-left: 22px; } }

    .container-main>p {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        color: $accent-4;
        padding: 0 22px;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            text-align: left; } }

    &__block {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        border-radius: 24px;
        background: $accent-3;
        padding: 24px 20px 32px 20px;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            flex-direction: column;
            gap: 48px; } }

    &__item {
        max-width: 444px;
        width: 100%;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: -26px;
            left: 0;
            border: 1px solid rgb(193, 197, 220);
            display: none;

            @media (max-width: $lg) {
                display: block; } }

        &:last-child {
            &::after {
                @media (max-width: $lg) {
                    display: none; } } }

        h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
            color: $accent-5;
            margin-bottom: 12px;
            font-family: 'Outfit', sans-serif; }

        img {
            border-radius: 16px;

            @media (max-width: $lg) {
                height: auto;
                object-fit: cover; } } } }
