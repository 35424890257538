.footer {
    display: flex;
    align-items: center;
    margin-top: 52px;
    flex-direction: column;

    @media (max-width: $lg) {
        margin-top: 30px;
        padding-bottom: 0;
        padding-top: 16px; }

    &__bottom {
        height: 40px;
        display: flex;
        align-items: center;
        width: 100%;

        @media (max-width: $lg) {
            margin-top: 32px;
            height: auto; }

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $accent-4;

            @media (max-width: $lg) {
                font-size: 14px; } }

        .container-main {
            @media (max-width: $lg) {
                flex-direction: column-reverse !important; } }

        nav {
            ul {
                @media (max-width: $lg) {
                    justify-content: flex-start; } } } }

    .container-main {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $lg) {
            padding: 0 20px;
            flex-direction: column;
            gap: 16px;
            align-items: baseline;
            max-width: fit-content;
            margin: 0; } }

    >.container-main {
        min-height: 106px;

        @media (max-width: $lg) {
            min-height: initial; } }

    &__left {
        display: flex;
        align-items: center;
        gap: 32px;

        @media (max-width: $lg) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            margin-bottom: 32px; } }

    &__social {
        display: flex;
        align-items: center;
        gap: 16px;

        a {
            height: 36px;
            width: 36px;
            background: rgba(89, 94, 113, 1);
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center; } }

    ul {
        display: flex;
        align-items: center;
        gap: 32px;
        max-width: 466px;
        flex-wrap: wrap;
        row-gap: 8px;
        justify-content: flex-end;

        @media (max-width: $lg) {
            justify-content: flex-start;
            row-gap: 32px; }

        li {
            @media (max-width: $lg) {
                max-width: calc( 50% - 16px );
                width: 100%; } }

        a {
            color: rgba(28, 33, 50, 1);
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;

            @media (max-width: $lg) {
                font-size: 16px;
                max-width: 50%;
                width: 100%; } } } }
