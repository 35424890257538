.table-of-contents {
    background: #020611;
    padding-bottom: 70px;
    padding-top: 70px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        padding-top: 40px;
        padding-bottom: 24px; }

    h2 {
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
        font-family: 'Outfit', sans-serif;
        color: $accent-2;
        margin-bottom: 32px;
        text-align: center;

        @media (max-width: $lg) {
            font-size: 30px;
            font-weight: 600;
            line-height: 120%;
            margin-bottom: 24px;
            text-align: left;
            padding-left: 22px; } }

    &__block {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-wrap: wrap;
        row-gap: 16px;

        @media (max-width: $lg) {
            overflow-x: scroll;
            justify-content: flex-start;
            padding-left: 22px;
            padding-bottom: 10px; }

        &-item {
            display: contents;

            @media (max-width: $lg) {
                display: flex;
                gap: 8px; } }

        a {
            border: 2px solid rgba(238, 240, 255, 0.25);
            border-radius: 1000px;
            height: 44px;
            font-size: 14px;
            font-weight: 600;
            line-height: 140%;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            @media (max-width: $lg) {
                padding: 0 44px;
                white-space: nowrap;
                height: 40px; }

            &:hover {
                color: #C5C5C5; } }

        &-item {
            &:first-child {
                a {
                    &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                        max-width: 150px; }

                    &:nth-child(7), &:nth-child(8) {
                        max-width: 182.5px; } } }

            &:last-child {
                a {
                    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                        max-width: 182.5px; }

                    &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9) {
                        max-width: 150px; } } } } } }
