.experts {
    padding-top: 32px;
    padding-bottom: 44px;
    background: #0B0E17;
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    h2 {
        color: $accent-2;

        @media (max-width: $lg) {
            text-align: left;
            padding-left: 22px; } }

    .container-main>p {
        max-width: 620px;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        color: #A5AAC0;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            text-align: left;
            padding: 0 22px;
            max-width: fit-content; } }

    &__block {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 32px;
        column-gap: 60px;

        @media (max-width: $lg) {
            gap: 48px; } }

    &__item {
        max-width: 366px;
        width: 100%;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: -26px;
            left: 0;
            right: 0;
            border: 1px solid rgb(65, 70, 89);
            display: none;
            max-width: calc( 100% - 44px );
            margin: 0 auto;
            justify-content: center;

            @media (max-width: $lg) {
                display: flex; } }

        &:nth-child(3) {
            &::after {
                @media (max-width: $lg) {
                    display: none; }

                @media (max-width: 767px) {
                    display: block; } } }

        &:last-child {
            &::after {
                @media (max-width: $lg) {
                    display: none; } } }

        picture {
            display: flex;
            justify-content: center; }

        img {
            margin: 0 auto;
            max-width: 124px;
            height: 124px;
            width: 100%;
            border-radius: 100%;
            margin-bottom: 12px;
            object-fit: cover;
            display: flex;
            justify-content: center; }

        h3 {
            text-align: center;
            margin-bottom: 12px;
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
            color: $accent-2;
            font-family: 'Outfit', sans-serif; }

        p {
            margin: 0 auto;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            color: #A5AAC0;
            max-width: 340px;

            @media (max-width: $lg) {
                padding: 0 22px;
                max-width: fit-content; } } } }
