.how-to-play {
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    .section-sub-title, h2 {
        @media (max-width: $lg) {
            text-align: left;
            padding-left: 22px; } }

    &__block {
        background: $accent-3;
        padding: 24px 20px 28px 20px;
        border-radius: 32px;

        button,a {
            background: initial;
            max-width: 365px;
            height: 52px;
            color: $accent-5;
            margin: 0 auto;

            &:hover {
                color: $accent-5; } }

        hr {
            margin: 32px 0;
            background: #C1C5DC;

            @media (max-width: $lg) {
                margin: 24px 0; } } }

    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        @media (max-width: $lg) {
            gap: 24px; } }

    &__item {
        max-width: 444px;
        position: relative;

        div {
            position: absolute;
            left: 0;
            top: -30px;
            right: 0;
            bottom: 0;
            display: flex;
            margin: auto;
            height: 58px;
            width: fit-content;
            text-align: center;
            font-size: 42px;
            font-weight: 600;
            color: #FDB365; }

        &:first-child {
            a {
                position: absolute;
                left: 0;
                top: -30px;
                right: 0;
                bottom: 0;
                display: flex;
                margin: auto;
                border: 1px solid #B4F0A6;
                background: #3AA720;
                height: 58px;
                width: 100%;
                max-width: 298px;
                border-radius: 16px;
                color: white;
                font-size: 24px;
                line-height: 28px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 4px 32px 0px #3AA720;

                @media (max-width: $lg) {
                    max-width: 250px;
                    top: 0; } } }

        h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
            color: $accent-5;
            margin-bottom: 12px;
            font-family: 'Outfit', sans-serif;

            @media (max-width: $lg) {
                font-size: 22px;
                font-weight: 600;
                line-height: 28px; } }

        img {
            margin-bottom: 12px;

            @media (max-width: $lg) {
                height: auto; } }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            color: $accent-4; } }

    &__text {
        display: flex;
        gap: 32px;
        margin-bottom: 32px;

        @media (max-width: $lg) {
            flex-direction: column;
            gap: 48px;
            margin-bottom: 24px; }

        &-item {
            max-width: 444px;
            width: 100%;

            &:first-child {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    bottom: -26px;
                    left: 0;
                    border: 1px solid rgb(193, 197, 220);
                    display: none;

                    @media (max-width: $lg) {
                        display: block; } } }

            h3 {
                font-size: 24px;
                font-weight: 600;
                line-height: 120%;
                margin-bottom: 12px;
                font-family: 'Outfit', sans-serif;
                color: $accent-5; }

            p {
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
                color: $accent-4; } } } }
