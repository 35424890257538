.bonus {
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    .container-main>p {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        color: $accent-4;

        @media (max-width: $lg) {
            padding: 0 22px;
            text-align: left;
            max-width: fit-content; } }

    .section-title {
        @media (max-width: $lg) {
            padding-left: 22px;
            text-align: left; } }

    .bonus__block {
        display: flex;
        gap: 32px;
        flex-wrap: wrap;
        justify-content: center;

        @media (max-width: $lg) {
            gap: 24px; } }

    .bonus__item {
        border-radius: 24px;
        background: $accent-3;
        max-width: calc((100% / 2) - 16px);
        width: 100%;

        @media (max-width: $lg) {
            max-width: 100%; }

        &-img {
            height: 172px;
            border: 4px solid $accent-3;
            border-radius: 24px;
            background: #FBFBFE;
            margin-bottom: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 24px; }

        &-star {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 28px;
            font-weight: 500;
            line-height: 32px;
            color: $accent-4;
            font-family: 'Outfit', sans-serif; }

        &-btns {
            display: flex;
            align-items: center;
            gap: 10px;

            a {
                height: 52px;

                &:last-child {
                    background: initial;
                    color: #030406; } } }

        &-text {
            padding: 0 20px;
            padding-bottom: 24px;

            p {
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
                color: $accent-4;
                margin-bottom: 12px; }

            ul {
                list-style: disc;
                padding-left: 20px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                margin-bottom: 24px;

                li {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 120%;
                    font-family: 'Outfit', sans-serif;
                    color: $accent-5; } } } } }
