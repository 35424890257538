.reviews {
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    &__block {
        display: flex;
        gap: 32px;
        flex-wrap: wrap;

        @media (max-width: $lg) {
            gap: 24px; }

        .reviews__item {
            max-width: calc( 50% - 56px );

            @media (max-width: $lg) {
                max-width: 100%; } } }

    &__wrapper {
        align-items: normal; }

    .container-main {
        position: relative; }

    .section-sub-title, .section-title {
        text-align: left;
        padding-left: 20px; }

    &__btns {
        position: absolute;
        right: 20px;
        top: 26px;
        display: flex;
        align-items: center;
        gap: 8px;

        @media (max-width: $lg) {
            top: 14px; } }

    &__btn {
        position: static;
        height: 32px;
        width: 32px;
        background: #c6cdea;

        svg {
            width: 6px !important; } }

    .swiper-button-prev {
        display: block; }

    .swiper-button-disabled {
        opacity: 0.5;
        display: flex; }

    &__item {
        max-width: 340px;
        width: 100%;
        border-radius: 24px;
        padding: 24px 20px 24px 20px;
        background: $accent-3;

        @media (max-width: $lg) {
            max-width: calc( 100vw - 100px ); }

        &-block {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 12px; }

        img {
            max-width: 84px;
            width: 100%;
            height: 84px;
            object-fit: cover;
            border-radius: 100%; }

        h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
            color: $accent-5;
            margin-bottom: 12px;
            font-family: 'Outfit', sans-serif; }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            color: $accent-4; } } }
