.aviator-features {
    scroll-margin-top: 160px;
    margin-bottom: 80px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    .section-sub-title, h2 {
        @media (max-width: $lg) {
            text-align: left;
            padding-left: 22px; } }

    table {
        border: 1px solid #EEEEF9;
        border-radius: 16px;
        width: 100%;
        border-spacing: 0;

        @media (max-width: $lg) {
            border-radius: 24px; }

        tr {
            height: 67px;

            @media (max-width: $lg) {
                height: 95px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center; }

            &:nth-child(odd) {
                background: #EEEEF9; }

            &:nth-child(even) {
                background: #FBFBFE; }

            &:first-child {
                td {
                    &:first-child {
                        border-radius: 16px 0 0 0; }

                    &:last-child {
                        border-radius: 0 16px 0 0; } }

                @media (max-width: $lg) {
                    border-radius: 16px 16px 0 0; } }

            &:last-child {
                td {
                    &:first-child {
                        border-radius: 0 0 0 16px; }

                    &:last-child {
                        border-radius: 0 0 16px 0; } } }

            td {
                width: 50%;

                @media (max-width: $lg) {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 8px; }

                &:first-child {
                    padding-left: 20px;
                    p {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 120%;
                        color: $accent-5;
                        font-family: 'Outfit', sans-serif; } }

                &:last-child {
                    border-radius: 0 16px 0 0;
                    padding-right: 20px;

                    @media (max-width: $lg) {
                        padding-right: 0;
                        padding-left: 20px; }

                    p {
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 150%;
                        color: $accent-4; } } } } } }
