.hamburger {
    align-items: center;
    display: flex;
    flex-direction: column;
    outline: none;
    transition: transform .2s ease;
    justify-content: space-between;
    text-decoration: none;
    height: 12px;
    width: 18px;
    position: relative;
    z-index: 10;
    margin-left: 22px;
    margin-right: 30px;

    @media (max-width: $lg) {
        margin-right: 0; }

    &::before, &::after {
        background: #A5AAC0;
        border-radius: 3px;
        content: "";
        display: block;
        transition: transform .2s ease;
        height: 2px;
        width: 100%; }

    &.is-active {
        transition: transform .3s ease;

        &::before, &::after {
            width: 100%; }

        &::before, &::after {
            top: 50%;
            position: absolute; }

        &::after {
            transform: rotate(-45deg); }

        &::before {
            transform: rotate(45deg); } } }
