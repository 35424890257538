.table-winners-month {
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    .section-sub-title, h2 {
        @media (max-width: $lg) {
            text-align: left;
            padding-left: 22px; } }

    .container-main>p {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        color: $accent-4;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            text-align: left;
            padding: 0 22px; } }

    &__btns {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 0 auto;
        margin-bottom: 24px;
        justify-content: center;

        @media (max-width: $lg) {
            padding: 0 22px; }

        button {
            max-width: 178px;
            width: 100%;
            height: 52px;
            background: initial;
            color: #030406;

            &.active {
                background: $accent-1;
                color: $accent-2;

                &:hover {
                    color: $accent-2; } }

            &:hover {
                color: #030406; } } }

    .js-tab-content {
        display: none;
        &.active {
            display: block; } }

    &__table {
        border: 1px solid #EEEEF9;
        border-radius: 16px;

        @media (max-width: $lg) {
            overflow: auto; }

        table {
            width: 100%;
            border-spacing: 0;

            @media (max-width: $lg) {
                min-width: 34.75rem;
                overflow: auto; }

            svg {
                margin-right: 8px; }

            p {
                display: inline-block;
                font-size: 18px;
                font-weight: 600;
                line-height: 120%;
                font-family: 'Outfit', sans-serif;

                @media (max-width: $lg) {
                    margin-left: 14px; } }

            tbody {
                border-radius: 16px;
                -webkit-border-radius: 16px;
                -moz-border-radius: 16px;
                -khtml-border-radius: 16px;
                overflow: hidden;
                font-family: 'Outfit', sans-serif;

                tr {
                    height: 72px;

                    @media (max-width: $lg) {
                        height: 92px; }

                    &:first-child {
                        border-radius: 16px 16px 0 0;
                        -webkit-border-radius: 16px 16px 0 0;
                        -moz-border-radius: 16px 16px 0 0;
                        -khtml-border-radius: 16px 16px 0 0;
                        overflow: hidden;

                        @media (max-width: $lg) {
                            height: 52px; }

                        td {
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 120%;
                            color: $accent-4;
                            font-family: 'Outfit', sans-serif;

                            &:first-child {
                                border-radius: 16px 0 0 0;
                                -webkit-border-radius: 16px 0 0 0;
                                -moz-border-radius: 16px 0 0 0;
                                -khtml-border-radius: 16px 0 0 0;
                                overflow: hidden; }

                            &:last-child {
                                border-radius: 0 16px 0 0;
                                -webkit-border-radius: 0 16px 0 0;
                                -moz-border-radius: 0 16px 0 0;
                                -khtml-border-radius: 0 16px 0 0;
                                overflow: hidden; } } }

                    &:last-child {
                        border-radius: 16px 16px 0 0;
                        -webkit-border-radius: 16px 16px 0 0;
                        -moz-border-radius: 16px 16px 0 0;
                        -khtml-border-radius: 16px 16px 0 0;
                        overflow: hidden;

                        td {
                            &:first-child {
                                border-radius: 0 0 0 16px;
                                -webkit-border-radius: 0 0 0 16px;
                                -moz-border-radius: 0 0 0 16px;
                                -khtml-border-radius: 0 0 0 16px;
                                overflow: hidden; }

                            &:last-child {
                                border-radius: 0 0 16px 0;
                                -webkit-border-radius: 0 0 16px 0;
                                -moz-border-radius: 0 0 16px 0;
                                -khtml-border-radius: 0 0 16px 0;
                                overflow: hidden; } } }

                    &:nth-child(odd) {
                        background: #EEEEF9; }

                    &:nth-child(even) {
                        background: #FBFBFE; } }

                td {
                    position: relative;

                    svg {
                        @media (max-width: $lg) {
                            position: absolute; } }

                    &:first-child {
                        width: 35%;
                        padding-left: 20px;

                        @media (max-width: $lg) {
                            width: 30%; }

                        p {
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 120%;
                            color: $accent-4;
                            font-family: 'Outfit', sans-serif;

                            @media (max-width: $lg) {
                                margin-left: 0; } } }

                    &:nth-child(2) {
                        width: 30%;
                        p {
                            color: #718BE3; }

                        @media (max-width: $lg) {
                            width: 30%; } }

                    &:nth-child(3) {
                        p {
                            color: #F09610; }

                        @media (max-width: $lg) {
                            width: 30%; } }
                    &:nth-child(4) {
                        p {
                            color: #39BF18; } }

                    &:last-child {
                        text-align: right;
                        padding-right: 20px; } } } } } }
