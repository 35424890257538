.verified {
    background: #0B0E17;
    padding: 32px 0;
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    h2 {
        color: $accent-2;

        @media (max-width: $lg) {
            font-size: 28px;
            font-weight: 600;
            line-height: 32px; } }

    &__imgs {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        max-width: 430px;
        justify-content: center;
        row-gap: 12px;
        column-gap: 63px;

        @media (max-width: $lg) {
            justify-content: center;
            padding: 0 40px;
            column-gap: 56px;
            row-gap: 0; } } }
