.other-page {
    .header__bottom {
        display: none; } }

.other-page {
    .header__nav {
        @media (max-width: $lg) {
            top: 52px; } } }

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    background: rgba(11, 14, 23, 0.99);
    transition: all 0.6s;

    &.active {
        position: fixed;
        top: 0; }

    &.hide {
        position: fixed;
        top: -86px;

        @media (max-width: $lg) {
            top: -52px; } }

    .container-main {
        max-width: 1024px;
        padding: 0 32px;
        background: #0E111CEB; }

    &__top {
        position: relative;
        display: flex;
        align-items: center;
        height: 86px;

        @media (max-width: $lg) {
            height: 52px;
            justify-content: space-between; } }

    &__bottom {
        display: flex;
        align-items: center;
        height: 52px;
        justify-content: space-between;
        gap: 25px;
        position: relative;

        @media (max-width: $lg) {
            gap: 20px; }

        &-drop {
            display: none;
            opacity: 0;
            flex-direction: column;
            gap: 18px;
            width: calc( 1088px - 64px );
            left: -30px;
            height: calc( 100vh - 104px );
            position: absolute;
            background: #000000ad;
            backdrop-filter: blur(10px);
            top: 52px;
            padding-left: 32px;
            padding-right: 32px;
            padding-top: 48px;
            padding-bottom: 16px;
            z-index: 2;
            transition: transform .3s ease;
            justify-content: flex-start;
            animation: 0.7s opacity ease;

            @media (max-width: $lg) {
                min-width: 100vw;
                left: -32px; }

            &.is-active {
                display: flex;
                opacity: 1; } }

        .header__bottom-menu {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: $accent-2;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            cursor: pointer; }

        .header__button {
            height: 32px;
            min-width: 93px;
            max-width: 93px;
            background: $accent-1;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            font-family: 'Outfit', sans-serif;

            @media (max-width: $lg) {
                max-width: 113px; }

            span {
                margin-left: 4px;

                @media (max-width: $lg) {
                    display: none; } } } }

    &__btns {
        display: flex;
        align-items: center;

        button {
            background: none;
            cursor: pointer; }

        .header__hamburger {
            display: none;

            @media (max-width: $lg) {
                display: flex; } }

        .header__button {
            height: 32px;
            min-width: 93px;
            background: $accent-1;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            font-family: 'Outfit', sans-serif;

            @media (max-width: $lg) {
                display: none; }

            &-search {
                display: none;
                @media (max-width: $lg) {
                    display: block; } }

            &-burger {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 6px;
                margin-left: 28px;
                margin-right: 28px;

                @media (max-width: $lg) {
                    display: flex; }

                &::after {
                    content: '';
                    display: block;
                    width: 18px;
                    height: 2px;
                    background: #A5AAC0; }

                &::before {
                    content: '';
                    display: block;
                    width: 18px;
                    height: 2px;
                    background: #A5AAC0; } } } }

    &__nav {
        max-width: 100%;
        width: 100%;
        justify-content: center;
        display: flex;

        &.is-active {
            left: -32px;
            transition: transform .3s ease; }

        @media (max-width: $lg) {
            min-width: 100vw;
            width: 100%;
            left: calc( -100vw - 32px );
            height: calc( 100vh - 104px );
            position: absolute;
            background: #000000ad;
            backdrop-filter: blur(10px);
            top: 104px;
            padding-top: 48px;
            padding-bottom: 16px;
            z-index: 2;
            transition: transform .3s ease;
            justify-content: flex-start; }

        ul {
            display: flex;
            align-items: center;
            height: 65px;
            flex-wrap: wrap;
            max-width: 600px;
            width: 100%;
            column-gap: 24px;
            justify-content: center;

            @media (max-width: $lg) {
                flex-direction: column;
                align-items: baseline;
                flex-wrap: nowrap;
                justify-content: flex-start;
                height: initial;
                padding: 0 32px;
                min-width: 180px;
                gap: 18px; }

            li {
                position: relative;
                height: 25px;
                display: flex;
                align-items: center;

                @media (max-width: $lg) {
                    height: auto; }

                &.menu-item-has-children {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    @media (max-width: $lg) {
                        flex-direction: column;
                        align-items: baseline;
                        width: 100%; }

                    &::after {
                        content: '';
                        width: 8px;
                        height: 6px;
                        display: block;
                        background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.669271 1.16667L4.0026 4.5L7.33594 1.16667' stroke='%23C5C5C5' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100%;

                        @media (max-width: $lg) {
                            position: absolute;
                            right: 0;
                            top: 6px; } } }

                a {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21px;
                    color: $accent-4;

                    @media (max-width: $lg) {
                        font-size: 18px;
                        color: #A5AAC0; } }

                ul {
                    position: absolute;
                    left: -6px;
                    width: 200px;
                    background: #07090E;
                    padding: 8px;
                    display: none;
                    top: 25px;
                    gap: 10px;
                    flex-direction: column;
                    align-items: baseline;
                    border: 1px solid rgb(85, 85, 85);
                    border-radius: 0 0 16px 16px;
                    z-index: 3;
                    height: initial;

                    @media (max-width: $lg) {
                        position: static;
                        width: 100%;
                        border: none;
                        border-radius: 0;
                        padding: 0;
                        padding-left: 10px;
                        background: none; } }

                &:focus-within, &:hover {
                    >ul {
                        display: flex; } } } } } }
