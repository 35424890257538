.promocode {
    padding-top: 32px;
    padding-bottom: 44px;
    background: #0B0E17;
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    h2 {
        color: $accent-2;

        @media (max-width: $lg) {
            text-align: left;
            padding-left: 22px; } }

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 24px;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            aspect-ratio: 2/1;
            min-height: 224px; } }

    p {
        max-width: 920px;
        width: 100%;
        margin: 0 auto;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        margin-bottom: 24px;
        color: #A5AAC0;

        @media (max-width: $lg) {
            text-align: left;
            padding: 0 22px;
            max-width: fit-content; } }

    a {
        max-width: 366px;
        height: 52px;
        margin: 0 auto;

        @media (max-width: $lg) {
            max-width: calc( 100% - 44px ); } } }
