// Starter template
@import './page/page';
@import "./hamburger/hamburger";
@import "./module/module";

// Components
@import "./footer/footer";
@import "./header/header";
@import "./overlay/overlay";

// Sections
@import "./sections/promo/promo";
@import "./sections/table-of-contents/table-of-contents";
@import "./sections/about/about";
@import "./sections/download/download";
@import "./sections/table-winners-month/table-winners-month";
@import "./sections/how-to-play/how-to-play";
@import "./sections/aviator-features/aviator-features";
@import "./sections/casino-game/casino-game";
@import "./sections/tips/tips";
@import "./sections/strategy/strategy";
@import "./sections/verified/verified";
@import "./sections/top-casino/top-casino";
@import "./sections/experts/experts";
@import "./sections/promocode/promocode";
@import "./sections/bonus/bonus";
@import "./sections/reviews/reviews";
@import "./sections/banner/banner";
@import "./sections/faq/faq";
