.about {
    position: relative;
    overflow: hidden;
    padding: 80px 0;
    background: #0B0E17;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        padding: 40px 0; }

    .container-main {
        position: relative;
        z-index: 2;

        @media (max-width: $lg) {
            padding: 0 32px; }

        button, a {
            max-width: 365px;
            height: 52px;
            margin: 0 auto;

            @media (max-width: $lg) {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px; } } }

    .section-sub-title {
        @media (max-width: $lg) {
            text-align: left; } }

    h2 {
        margin-top: 8px;
        text-align: center;
        font-size: 44px;
        font-weight: 600;
        line-height: 52px;
        margin-bottom: 24px;
        font-family: 'Outfit', sans-serif;

        @media (max-width: $lg) {
            text-align: left;
            font-size: 30px;
            font-weight: 600;
            line-height: 120%; }

        span {
            &:first-child {
                @media (max-width: $lg) {
                    display: none; } }
            &:last-child {
                display: none;
                @media (max-width: $lg) {
                    display: block; } } } }

    .container-main>p {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 24px;
        text-align: center;
        color: $accent-2;

        @media (max-width: $lg) {
            text-align: left; }

        span {
            display: block;
            color: #8B90A6;

            @media (max-width: $lg) {
                display: inline;
                margin-left: 6px; } } }

    &__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 756px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            flex-wrap: wrap;
            gap: 20px;
            row-gap: 24px;
            justify-content: flex-start; } }

    &__item {
        h3 {
            font-size: 32px;
            font-weight: 500;
            line-height: 36px;
            margin-bottom: 2px;
            font-family: 'Outfit', sans-serif;
            color: $accent-2; }

        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #71768B; } } }
