.download {
    padding-top: 80px;
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        padding-top: 44px; }

    .section-sub-title, .section-title {
        @media (max-width: $lg) {
            text-align: left;
            padding: 0 22px; } }

    &__images {
        margin-bottom: 24px;

        img {
            border-radius: 24px;

            @media (max-width: $lg) {
                height: 400px;
                object-fit: cover; } } }

    p {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        color: $accent-4;
        padding: 0 22px;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            text-align: left; } }

    &__btns {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;
        margin: 0 auto; } }
