.promo {
    position: relative;
    padding-top: 40px;

    @media (max-width: $lg) {
        padding-top: 25px;
        padding-bottom: 0; }

    .container-main {
        @media (max-width: $lg) {
            padding: 0; } }

    &__bg {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;

        picture {
            width: 100%; }

        img {
            width: 100%;
            max-width: 100%;
            object-fit: cover; } }

    h1 {
        text-align: center;
        font-family: 'Outfit', sans-serif;
        font-size: 64px;
        line-height: 78px;
        font-weight: 600;
        margin-bottom: 20px;
        color: $accent-2;

        @media (max-width: $lg) {
            margin-bottom: 24px;
            font-size: 42px;
            font-weight: 600;
            line-height: 120%; } }

    .promo__block {
        position: relative;

        .promo-img-desk {
            @media (max-width: $lg) {
                display: none; }

            picture {
                width: 100%; }

            img {
                width: 100%;
                object-fit: cover; } }

        .promo-img-mob {
            display: none;
            justify-content: center;

            @media (max-width: 767px) {
                display: flex; }

            img {
                width: 100%;
                object-fit: cover;
                max-width: calc(100vw - 40px);
                height: auto; } }

        &-text {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @media (max-width: $lg) {
                position: static;
                margin-top: 1.5rem;
                background: {}
                background: linear-gradient(180.32deg, #02061106 15%, #02061129 15%, #02061194 20%,#020611 50%); }

            img {
                margin-bottom: 18px;

                @media (max-width: $lg) {
                    margin-bottom: 16px; } }

            h2 {
                font-family: 'Outfit', sans-serif;
                background: linear-gradient(180.32deg, rgb(255, 255, 255),#1B3DF0);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-size: 28px;
                font-weight: 500;
                line-height: 36px;
                text-align: center;
                max-width: 620px;
                margin-bottom: 18px;

                @media (max-width: $lg) {
                    margin-bottom: 24px;
                    padding: 0 32px; } }

            button, a {
                max-width: 365px;
                height: 52px;

                @media (max-width: $lg) {
                    max-width: calc( 100% - 64px ); } } } } }
