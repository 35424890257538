.top-casino {
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    .container-main>p {
        max-width: 920px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
        color: $accent-4;

        @media (max-width: $lg) {
            text-align: left;
            padding: 0 22px;
            max-width: fit-content; } }

    .section-sub-title, h2 {
        @media (max-width: $lg) {
            text-align: left;
            padding-left: 22px; } }

    &__block {
        display: flex;
        gap: 32px;
        flex-wrap: wrap;

        @media (max-width: $lg) {
            gap: 24px; } }

    &__item {
        max-width: 464px;
        width: 100%;
        border-radius: 24px;
        background: $accent-3;
        padding-bottom: 24px;

        img {
            margin-bottom: 24px;

            @media (max-width: $lg) {
                aspect-ratio: 2/1;
                min-height: 172px; } }

        h3 {
            font-size: 30px;
            font-weight: 600;
            line-height: 120%;
            color: $accent-5;
            margin-bottom: 24px;
            font-family: 'Outfit', sans-serif;
            padding: 0 20px; }

        &-row {
            display: flex;
            align-items: center;
            gap: 40px;
            padding: 0 20px; }

        &-star, &-bonus {
            h4 {
                display: flex;
                gap: 9px;
                align-items: center;
                margin-bottom: 6px;
                font-size: 30px;
                font-weight: 600;
                line-height: 120%;
                margin-bottom: 0px;
                font-family: 'Outfit', sans-serif;
                color: $accent-5; }

            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
                color: #8B90A6; } }

        >p {
            padding: 0 20px;
            margin: 24px 0;
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            color: #030406; }

        ul {
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 24px;

            li {
                display: flex;
                align-items: center;
                gap: 11px;
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
                color: $accent-4; } }

        a {
            height: 52px;
            max-width: calc( 100% - 40px );
            margin: 0 auto; } } }
