body {
    background: #FBFBFE;
    font-family: "Inter";
    margin: 0;
    font-weight: 500; }

.page {
    overflow-x: hidden;
    @media (min-width: 1921px) {
        max-width: none !important; }

    &__content {
        position: relative;
        padding-top: 138px;
        overflow-y: hidden;

        @media (max-width: $lg) {
            padding-top: 104px; } } }

.other-page {
    .page__content {
        padding-top: 86px;

        @media (max-width: $lg) {
            padding-top: 52px; } } }

.container-main {
    max-width: 960px;
    margin: 0 auto;

    @media (max-width: $lg) {
        padding: 0 10px; } }

.title-page {
    margin-bottom: 24px;
    font-size: 48px;
    font-weight: 600;
    line-height: 120%;
    font-family: 'Outfit', sans-serif;
    text-align: left;
    color: $accent-5;
    padding-left: 22px;

    @media (max-width: $lg) {
        font-size: 36px;
        font-weight: 600;
        line-height: 50.4px; } }

.section-title {
    color: $accent-5;
    margin-bottom: 32px;
    font-size: 42px;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
    font-family: 'Outfit', sans-serif;

    @media (max-width: $lg) {
        font-size: 30px;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 24px; } }

.text-block {
    .section-title {
        text-align: left;
        padding-left: 22px; }

    h2 {
        color: $accent-5;
        margin-bottom: 24px;
        font-size: 42px;
        font-weight: 600;
        line-height: 120%;
        text-align: left;
        padding-left: 22px;
        font-family: 'Outfit', sans-serif;

        @media (max-width: $lg) {
            font-size: 30px;
            font-weight: 600;
            line-height: 120%; } }

    p {
        margin-bottom: 24px; }

    .download {
        .swiper-wrapper {
            height: initial; } } }

iframe {
    display: flex;
    margin: 0 auto;

    @media (max-width: $lg) {
        width: 100%; } }

.video {
    margin-top: 24px; }

.tags {
    ul {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            overflow-x: scroll;
            padding-bottom: 12px;
            margin-bottom: 12px; }

        button, li {
            border: 2px solid rgb(139, 144, 166);
            border-radius: 100px;
            padding: 0px 44px 0px 44px;
            font-size: 14px;
            font-weight: 600;
            line-height: 140%;
            text-transform: uppercase;
            height: fit-content;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: $lg) {
                min-width: fit-content; }

            a {
                color: #414659;
                pointer-events: none;
                cursor: default; } } } }

.section-sub-title {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: center;
    color: $accent-4; }

.text-page {
    color: rgb(197, 197, 197);
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    margin-top: 36px;

    @media (max-width: $lg) {
        margin-top: 24px;
        font-size: 16px; } }

a {
    text-decoration: none;
    color: #C5C5C5;
    transition-property: all;
    transition-duration: .6s;

    &:hover {
        opacity: 1;
        color: initial; } }

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid $accent-1;
    border-radius: 100px;
    text-decoration: none;
    color: $accent-2;
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
    transition-property: all;
    transition-duration: .6s;
    width: 100%;
    background: $accent-1;
    font-family: 'Outfit', sans-serif;

    &:hover {
        opacity: 0.8;
        color: white; } }

h1,h2,h3,h4,h5,h6,p {
    padding: 0;
    margin: 0;
    line-height: 150%;
    color: #C5C5C5; }

button {
    border: none;
    transition-property: all;
    transition-duration: .6s;
    padding: 0;
    background: none;

    &:hover {
        opacity: 0.8; } }

img {
    @media (max-width: $lg) {
        max-width: 100%; } }

.modal-dialog {
    pointer-events: initial;
    margin: 0 auto;
    justify-content: center; }

@keyframes opacity {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

.section-small {
    scroll-margin-top: 160px;
    margin-bottom: 52px;

    @media (max-width: $lg) {
        margin-bottom: 44px;
        padding: 0 10px; }

    .container-main {
        padding-top: 24px;
        background: $accent-3;
        border-radius: 24px;

        @media (max-width: $lg) {
            padding: 0;
            padding-top: 24px; } }

    h2 {
        @media (max-width: $lg) {
            margin-bottom: 12px;
            text-align: left;
            padding-left: 22px; } }

    img {
        @media (max-width: $lg) {
            aspect-ratio: 2/1;
            min-height: 224px;
            max-height: 224px;
            border-radius: 24px;
            object-fit: cover; } }

    p {
        max-width: 625px;
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 24px;
        color: $accent-4;

        @media (max-width: $lg) {
            text-align: left;
            padding: 0 22px;
            max-width: fit-content; } } }

.articles-home {
    margin-bottom: 80px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    .container-main {
        display: flex;
        flex-direction: column;
        gap: 80px;

        @media (max-width: $lg) {
            gap: 44px; } } }

.article-home {
    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 24px;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            aspect-ratio: 2/1;
            min-height: 224px; } }

    h2 {
        @media (max-width: $lg) {
            text-align: left;
            padding-left: 22px; } }

    p {
        max-width: 920px;
        width: 100%;
        margin: 0 auto;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        margin-bottom: 24px;
        color: $accent-4;

        @media (max-width: $lg) {
            padding: 0 22px;
            text-align: left;
            max-width: fit-content; } }

    a {
        max-width: 366px;
        height: 52px;
        margin: 0 auto;

        @media (max-width: $lg) {
            background: initial;
            color: #030406;
            max-width: calc( 100% - 44px ); } } }

.article {
    scroll-margin-top: 160px;

    .title-page {
        padding-left: 0; }

    .faq {
        margin-top: 24px; }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        a {
            color: blue; }

        img {
            margin-bottom: 12px;
            max-width: 100%;
            height: auto;
            object-fit: cover; }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            color: $accent-4;
            margin-bottom: 0; }

        ul,ol {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding-left: 16px;

            li {
                font-size: 16px;
                font-weight: 500;
                line-height: 160%;
                color: #71768b;

                &::marker {
                    color: #71768b; } } }

        ul {
            list-style: disc; }
        ol {
            list-style: decimal; }

        h2 {
            margin-bottom: 4px;
            font-size: 42px;
            font-weight: 600;
            line-height: 120%;
            color: #030406; }

        h3 {
            margin-bottom: 4px;
            font-size: 30px;
            font-weight: 600;
            line-height: 120%;
            color: #030406; }

        h4 {
            margin-bottom: 4px;
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
            color: #030406; }

        h5 {
            margin-bottom: 4px;
            font-size: 18px;
            font-weight: 600;
            line-height: 120%;
            color: #030406; }

        h6 {
            margin-bottom: 4px;
            font-size: 16px;
            font-weight: 600;
            line-height: 120%;
            color: #030406; } } }

.text-block-v2 {
    margin-top: 120px;

    @media (max-width: $lg) {
        margin-top: 30px; }

    .section-title {
        margin-bottom: 36px;

        @media (max-width: $lg) {
            margin-bottom: 24px; } }

    &__block {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        @media (max-width: $lg) {
            flex-direction: column;
            gap: 16px; } }

    &__item {
        border-radius: 16px;
        flex: 1;
        border-radius: 24px;
        background: rgb(238, 238, 249);
        padding: 24px 20px 24px 20px;

        @media (max-width: $lg) {
            padding: 32px 16px; }

        h3 {
            font-size: 30px;
            font-weight: 600;
            line-height: 120%;
            margin-bottom: 16px;
            color: $accent-5;
            font-family: 'Outfit', sans-serif; }

        ul {
            display: flex;
            flex-direction: column;
            gap: 12px;

            li {
                font-size: 18px;
                font-weight: 600;
                line-height: 150%;
                color: $accent-4;
                display: flex;
                align-items: center;

                @media (max-width: $lg) {
                    font-size: 16px; }

                span, strong {
                    color: $accent-1;
                    margin-left: 4px; } } } } }

.kama_breadcrumbs {
    margin-top: 12px;
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    gap: 12px;

    span {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        color: #71768B; } }

.text-page {
    .section-title {
        text-align: left; }

    a {
        color: blue; }

    p {
        color: $accent-4;
        font-size: 18px;
        line-height: 120%;
        margin-bottom: 18px;
        text-align: left;

        a {
            color: blue; } }

    h1, h2, h3, h4, h5, h6 {
        color: $accent-5;
        margin-bottom: 24px;
        text-align: left;
        padding-left: 0 !important;
        font-family: 'Outfit', sans-serif; }

    ul, ol {
        color: #71768b;
        list-style: disc;
        text-align: left;
        padding-left: 26px;
        margin-bottom: 20px;

        a {
            color: blue; }

        ul {
            list-style: disc;
            padding-left: 24px;
            margin-bottom: 0; } }

    ol {
        list-style: auto; }

    .faq .quiz__qna-list-item-answer-text {
        text-align: left; } }
