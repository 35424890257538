.faq {
    margin-top: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    h2 {
        text-align: left;
        padding: 0 20px; }

    .quiz__qna {
        gap: 16px;
        display: flex;
        flex-direction: column; }

    .quiz__qna-list-item {
        position: relative;
        cursor: pointer;
        background: $accent-3;
        border-radius: 12px;
        padding-left: 20px;
        padding-right: 20px; }

    .quiz__qna-list-item-question {
        height: 53px;
        font-size: 24px;
        font-weight: 600;
        line-height: 120%;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
        color: $accent-5;
        font-family: 'Outfit', sans-serif;

        @media (max-width: $lg) {
            max-width: 325px;
            height: 82px; } }

    .quiz__qna-list-item-answer {
        color: rgb(243, 243, 243);
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        color: $accent-4;

        &-text {
            padding-top: 12px;
            padding-bottom: 24px; } }

    .quiz__qna-list-item-close {
        position: absolute;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        right: 20px;
        top: 10px;

        @media (max-width: $lg) {
            top: 20px; }

        svg {
            width: 30px;
            height: 30px; } }

    .quiz__qna-list-item.active {
        .quiz__qna-list-item-answer {
            max-height: initial;
            opacity: 1; }

        .quiz__qna-list-item-close {
            transform: rotate(180deg); } } }
