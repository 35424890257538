.tips {
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    .section-sub-title, h2 {
        @media (max-width: $lg) {
            text-align: left;
            padding-left: 22px; } }

    &__block {
        @media (max-width: $lg) {
            display: flex;
            flex-direction: column-reverse; } }

    picture {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            justify-content: flex-start;
            margin: 0;
            margin-bottom: 24px;
            margin-left: 22px; } }

    img {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            justify-content: flex-start;
            margin: 0;
            margin-bottom: 24px;
            margin-left: 22px; }
        @media (max-width: $lg) {
            max-width: 124px;
            height: auto; } }

    p {
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
        color: $accent-4;
        padding: 0 20px;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            text-align: left; } }

    button,a {
        background: initial;
        color: $accent-5;
        max-width: 365px;
        height: 52px;
        margin: 0 auto;

        @media (max-width: $lg) {
            max-width: calc( 100% - 40px ); }

        &:hover {
            color: $accent-5; } } }
