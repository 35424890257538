.other-page {
    img {
        border-radius: 24px; } }

.banner {
    scroll-margin-top: 160px;
    position: relative;
    margin-bottom: 24px;

    picture {
        width: 100%;
        display: flex;
        justify-content: center; }

    img {
        max-width: 1440px;
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 24px;

        @media (max-width: $lg) {
            height: 530px; } }

    &__text {
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        margin: 0 auto;
        bottom: 44px;
        flex-direction: column;

        h2 {
            font-size: 44px;
            font-weight: 600;
            line-height: 52px;
            font-family: 'Outfit', sans-serif;
            color: #FBFBFE;
            margin-bottom: 32px;
            text-align: center; }

        a {
            height: 52px;
            max-width: 365px;
            margin: 0 auto;

            @media (max-width: $lg) {
                max-width: calc( 100vw - 64px ); } } } }
