.strategy {
    margin-bottom: 80px;
    scroll-margin-top: 160px;

    @media (max-width: $lg) {
        margin-bottom: 44px; }

    .container-main {
        position: relative; }

    .section-sub-title, .section-title {
        text-align: left;
        padding-left: 20px; }

    &__wrapper {
        align-items: normal; }

    &__btns {
        position: absolute;
        right: 20px;
        top: 50px;
        display: flex;
        align-items: center;
        gap: 8px;

        @media (max-width: $lg) {
            top: 40px; } }

    &__btn {
        position: static;
        height: 32px;
        width: 32px;
        background: #c6cdea;

        svg {
            width: 6px !important; } }

    .swiper-button-prev {
        display: block; }

    .swiper-button-disabled {
        opacity: 0.5;
        display: flex; }

    &__item {
        max-width: 340px;
        width: 100%;
        border-radius: 24px;
        padding: 24px 20px 22px 20px;
        background: $accent-3;

        @media (max-width: $lg) {
            max-width: calc( 100vw - 80px ); }

        img {
            max-width: 124px;
            width: 100%;
            height: 124px;
            object-fit: cover;
            margin-bottom: 12px; }

        h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
            color: $accent-5;
            margin-bottom: 12px;
            font-family: 'Outfit', sans-serif; }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            color: $accent-4;
            margin-bottom: 12px; }

        a {
            display: flex;
            align-items: center;
            gap: 12px;
            font-size: 16px;
            font-weight: 600;
            line-height: 120%;
            font-family: 'Outfit', sans-serif;
            color: $accent-1; } } }
