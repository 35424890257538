$accent: #07090e;
$accent-1: #4f619e;
$accent-2: #eef0ff;
$accent-3: #eeeef9;
$accent-4: #71768b;
$accent-5: #1c2132;

$font-family-base: "Inter";

$lg: "1023px";
